<template>
	<div class="page">
		<div class="page7">
			<div class="lxwmk">
				<img src="../assets/img/lxwm1.png" class="lxwm animate__animated"/>
				<div class="lxwmsrk animate__animated">
					<div class="lxwmbt">填写联系方式<br>客服会和您取得联系，开始试用</div>
					<div class="srkbt">电话号码</div>
					<input class="sws" type="text" v-model="fromdata.phone"/>
					<div class="tj" v-if="tjqny == 0" @click="lxwm()">提交</div>
					<div  class="tj1" v-if="tjqny == 1">{{tjants}}</div>
					<div class="btts" :class="{ bttsbxs: bttsxs }">{{btss}}</div>
				</div>
			</div>
		</div>
		<div class="foot">
			<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
			北京独木成林科技有限公司<br>
			Beijing One Tree Forest Technology Co., Ltd.
		</div>
	</div>
</template>

<style scoped>
.page{
	width: 100%;
	height: 100%;
}
.dxyd{
	width: 28px;
	height: 28px;
	border-radius: 14px;
	border: 1px solid #68747f;
	position: absolute;
	left: 30px;
	top: 16px;
	box-sizing: border-box;
}
.dxydxz{
	border: 10px solid #05ac73;
}
.xyljdcpxx{
	width: 100%;
	height: 60px;
	background: #f8fafc;
	margin-bottom: 20px;
	font-size: 21px;
	color: #616076;
	display: flex;
	justify-content: left;
	align-items: center;
	box-sizing: border-box;
	padding-left: 80px;
	position: relative;
}
.xljdcpxxk{
	width: 100%;
	margin-top: 20px;
}
.wlhnrdy{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 55px;
	line-height: 40px;
}
.wlhnrdy1{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 55px;
	line-height: 40px;
}
.wlhbtdy{
	font-size: 28px;
	line-height: 45px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
}
.gzdy{
	display: block;
	width: 400px;
	margin: 0 auto;
	margin-top: 50px;
	box-shadow: 0px 0px 43px 0px rgba(6, 0, 1, 0.1);
}
.page8{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	overflow: hidden;
	padding-bottom: 70px;
	padding-top: 70px;
}
.foot{
	width: 100%;
	background: #f5f9fa;
	margin-top: 50px;
	color: #68747f;
	line-height: 30px;
	text-align: center;
	font-size: 18px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.foot a{
	color: #68747f!important;
}
.tj{
	height: 60px;
	width: 100%;
	background: #05ac73;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #FFFFFF;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.yd{
	width: 21px;
	height: 21px;
	border-radius: 10.5px;
	border: 1px solid #68747f;
	position: absolute;
	left: 15px;
	top: 18px;
	box-sizing: border-box;
}
.gzsj{
	display: inline-block;
	height: 100%;
	box-sizing: border-box;
	padding-left: 50px;
	padding-right: 15px;
	position: relative;
	color: #68747f;
	font-size: 21px;
	line-height: 30px;
	text-align: center;
	border: 1px solid #e0e0e0;
	float: left;
	line-height: 58px;
	border-radius: 5px;
	margin-right: 25px;
}
.xz{
	border-color: #05ac73;
	color: #05ac73;
}
.xzyd{
	border: 8px solid #05ac73;
}
.dxk{
	height: 60px;
	width: 100%;
	margin-top: 20px;
}
.sws .xlysx{
	width: 429px;
}
.sws:focus{
	outline:none;
	border: 1px solid #05ac73;
}
.sws{
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #e0e0e0;
	font-size: 21px;
	color: #616076;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	line-height: 58px;
	margin-top: 20px;
	margin-bottom: 10px;
}
.srkbt{
	width: 100%;
	font-size: 23px;
	color: #616076;
	text-align: left;
	margin-top: 60px;
	margin-bottom: 20px;
}
.lxwmbt{
	width: 100%;
	font-size: 25px;
	color: #68747f;
	text-align: center;
	line-height: 40px;
	margin-bottom: 40px;
}
.lxwmsrk{
	width: 454px;
	height: calc(100vh - 300px);
	background: #FFFFFF;
	background-image: url(../assets/img/lxwmbg.png);
	background-repeat: no-repeat;
	background-size: 454px 103px;
	background-position: left 20px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	margin-top: -82px;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	padding-top: 70px;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 70px;
	overflow-y: auto;
}
.lxwm{
	width: 100%;
	display: block;
}
.lxwmk{
	width: 100%;
}
.page7{
	width: 100%;
	height: 100%;
	margin-bottom: 50px;
}
.btts{
	width: 100%;
	font-size: 23px;
	color: #F56C6C;
	text-align: center;
	margin-top: 40px;
}
.bttsbxs{
	display: none;
}
.tj1{
	height: 60px;
	width: 100%;
	background: #EBEEF5;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #909399;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>

<script>
import wxapi from './yd/wxapi.js';
export default {
	name: 'contact',
	data () {
		return {
			gzsj: true,
			fgzsj: false,
			btss:'请输入电话号码',
			bttsxs:true,
			tjqny:0,
			tjants:'提交中',
			bddxxx:{
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false
			},
			fromdata:{
			  phone: ''
			},
			ly:'yd',
			tjr:''
		}
	},
	mounted () {
		let that = this;
		let url = encodeURIComponent(location.href.split('#')[0]);
		let dz = window.location.href;
		this.$http('/wxjsapi?url=' + url).then(function (response) {
			let data = response.data;
			let option = {
				title: "榕树万千李金鹏",
				desc: "填写联系方式，客服会和您取得联系，开始试用。",
				link: dz,
				imgUrl: "https://www.dmclkj.com/slt.png",
			};
			wxapi.wxRegister(data,option);
		}).catch(function (error) {
		});
		let broser = window.navigator.userAgent.toLowerCase();
		if(broser.match(/MicroMessenger/i) == 'micromessenger'){
		     that.ly = "wx";
		}
		this.$http('/browse?source=' + that.ly + '&tjr=' + that.tjr).then(function (response) {
			
		}).catch(function (error) {
			
		});
	},
	methods: {
		lxwm(){
			let that = this;
			if(that.fromdata.phone == ''){
				that.btss = '请填写电话号码！';
				that.bttsxs = false;
			}else if(!(/^1[3456789]\d{9}$/.test(this.fromdata.phone))){
				that.btss = '请确认手机号是否正确！';
				that.bttsxs = false;
			}else{
				that.btss = '';
				that.bttsxs = true;
				that.tjqny = 1;
				that.tjants = '提交中';
				this.$http.post('/ontrial', {
					  phone: that.fromdata.phone
				  })
				  .then(function (response) {
					  if(response.data.errmsg=='ok'){
						  alert('提交成功！')
						  that.tjants = '提交成功';
						  that.fromdata.phone = '';
					  }else{
						  alert('网络异常，请再次提交！')
						  that.btss = '';
						  that.tjqny = 0;
						  that.bttsxs = false;
					  }
				  }).catch(function (error) {
				    alert('网络异常，请再次提交！')
					that.btss = '';
					that.tjqny = 0;
					that.bttsxs = false;
				  });
			}
		}
	}
};
</script>
